.vertical-tabs {
  .ant-tabs-nav {
    min-width: 125px;
  }
}

.margin-top {
  margin-top: 10px;
}

.margin-right {
  margin-right: 10px;
}
