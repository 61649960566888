.list-articles .ant-list-item-meta {
  margin-bottom: 0;
}

.list-articles .ant-list-item:hover {
  box-shadow: 0 1px 2px -2px #00000029, 0 3px 6px #0000001f, 0 5px 12px 4px #00000017;

  img {
    transform: scale(1.1);
    opacity: 0.5;
  }
}

.list-articles .ant-list-item {
  transition: box-shadow 0.35s linear;
  position: relative;
  margin-bottom: 16px;
}

.list-articles .ant-list-item-meta-avatar {
  position: relative;
  overflow: hidden;
  max-height: 180px;
}

.list-articles img {
  transition: transform 0.35s linear, opacity 0.35s linear;
}

.list-articles__button {
  position: absolute;
  bottom: 16px;
  right: 24px;
}

.list-articles .ant-list-pagination {
  text-align: left;
}

.portal .ant-card-body {
  padding: 20px;
}

.portal .ant-card-bordered {
  margin: 0 auto;
  width: 800px;
}

@media screen and (max-width: 1310px) {
  .portal .ant-card-bordered {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .ant-list-item,
  .ant-list-item-meta {
    display: block;
  }
  .ant-list-item-meta-content {
    width: 100%;
  }
  .list-articles img {
    width: 100% !important;
  }

  .ant-list-item-meta-avatar {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .list-articles__button {
    padding-top: 10px;
    position: inherit;
  }
}
