.company-detail__div {
  width: 100%;
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.ant-card-bordered {
  box-shadow: @box-shadow;
}

.flex-box {
  display: flex;
  flex-direction: column;

  &.company-detail__content {
    width: 100%;
    flex: 1;
    overflow: auto;
    overflow-x: hidden;

    > .p-card.p-component {
      height: calc(100vh - 262px);
      overflow: auto;
    }
  }

  &.company-detail__footer {
    margin-bottom: 0;
    padding-top: 5px;
    width: 100%;
  }
}

.title--no-margin {
  margin: 0 !important;
}

.delete-company-button {
  color: @white;
  background: @error-color;
}

.delete-company-alert .ant-alert-action {
  margin-top: 10px;
}

.async-with-modal {
  display: flex;
}
