.edit-tag {
  user-select: none;
}
.tag-input {
  width: 150px;
  margin-right: 8px;
}

.ant-input-group .ant-input.tag-add-input {
  width: calc(100% - 77px);
}

.tags-input {
  width: 100%;
}
