/* Typography */
h1 {
  margin: 1em 0 0.5em 0;
  font-weight: normal;
  font-size: 20px;

  &:first-child {
    margin: 0 0 20px 0;
  }
}

h2 {
  font-size: 18px;
  font-weight: normal;
  margin: 0;
}

h3 {
  font-size: 16px;
}

h4 {
  font-size: 14px;
}

h5 {
  font-size: 12px;
}

h6 {
  font-size: 10px;
}

a {
  text-decoration: none;
}

hr {
  border: none;
  border-bottom: 1px solid #d5d5d5;
}

hr.separator {
  margin: 16px 0 32px;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-error {
  margin-bottom: 10px;
  color: @error-color;
}

.text-warning {
  margin-bottom: 10px;
  color: @warning-color;
}

.p--no-margin {
  margin: 0;
}

.antd-modal .ant-input-number,
.antd-modal .ant-input-number-disabled {
  width: 100%;
}

.async-select .async-select__value-container--is-multi {
  height: auto !important;
}
.async-select .async-select__control {
  height: 32px !important;
}

.ant-picker {
  width: 100%;
}

.ant-input-number {
  width: 100%;
}

.tab-error {
  background: @error-color;
}

.centering {
  display: grid;
  place-content: center;
}

.loading-position {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading-position .ant-spin-dot {
  font-size: 50px;
}

.margin-right-8 {
  margin-right: 8px;
}

.margin-top-10 {
  margin-top: 10px;
}

.edit-button {
  width: 38px;
  height: 28px;
}

.edit-button svg {
  position: absolute;
  top: 5px;
  left: 12px;
}

.width-100 {
  width: 100%;
}

.margin-bottom {
  margin-bottom: 5px;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}
