.boolean-filter-item-container {
  padding-bottom: 16px;

  .boolean-filter-item-label {
    margin-right: 8px;
  }
}

.col-button-container button:nth-child(1) {
  margin-right: 8px;
}

.select-dropdown {
  width: 200px;
}

.select-dropdown .ant-select {
  width: 100%;
}

#components-table-demo-drag-sorting tr.drop-over-downward td {
  border-bottom: 2px dashed #1890ff;
}

#components-table-demo-drag-sorting tr.drop-over-upward td {
  border-top: 2px dashed #1890ff;
}
