.input-file {
  position: relative;
  width: 100%;
  height: 70px;
  display: block;
}
.input-file:after {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  margin: 5px 0;
  text-align: center;
  padding: 25px 0px;
  border-radius: 10px;
  border: 5px dashed #ccc;
}

.input-file:hover:after {
  background: #ccc;
}

.input-file .file {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 5;
  opacity: 0;
  cursor: pointer;
}
