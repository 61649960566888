.background-image {
  width: 100%;
  height: 76vh;
  background-image: url("../../images/image_test.png");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-container {
  position: relative;
  padding-top: 40%;
  width: 80%;
  border-radius: 50%;
  display: grid;
  place-items: center;

  .circle-item {
    position: absolute;
    width: 150px;
    height: 120px;
    display: grid;
    place-items: center;

    .app-container {
      width: 150px;
      height: 120px;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      transition: transform 0.6s cubic-bezier(0.25, 0.8, 0.25, 1);
      will-change: transform;
      backface-visibility: hidden;
      transform: translateZ(0);
      -webkit-font-smoothing: antialiased;

      .text {
        position: relative;
        height: 28px;
        text-align: center;
        align-content: center;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        background-color: var(--color);

        &:before {
          content: "";
          background-color: var(--color);
          position: absolute;
          width: 7px;
          height: 7px;
          bottom: -3px;
          left: 50%;
          margin-left: -3.5px;
          transform: rotate(45deg);
        }

        .ant-typography {
          color: #fff;
          font-size: 11px;
          will-change: transform;
          backface-visibility: hidden;
          -webkit-font-smoothing: antialiased;
        }
      }

      .ant-image {
        position: relative;
        height: 85px;
        width: 100%;
      }

      &:hover {
        transform: scale(1.05);

        .ant-image {
          transition: transform 0.6s cubic-bezier(0.25, 0.8, 0.25, 1);
          will-change: transform;
          backface-visibility: hidden;
          transform: translateZ(0);
        }
      }
    }
  }
}
