.highligth {
  height: @portal-height;
  overflow: auto;
  padding: 20px;
}

.highligth--big {
  height: 692px;
}

.highligth--medium,
.highligth--small {
  height: 330px;
}

.box {
  position: relative;
  margin: 0 auto;
  list-style: none;
}

.box__figure {
  position: relative;
  float: left;
  overflow: hidden;
  margin: 0;
  width: 100%;
  height: 100%;
  background: @primary-color;
  cursor: pointer;
}

.box__figure:hover figcaption::before,
.box__figurere:hover p {
  opacity: 1;
  transform: scale(1);
}

.box__image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  opacity: 0.5;
  transform: scale(1.11);
  transition: opacity 0.35s, transform 0.35s;
}

.box:hover .box__image {
  opacity: 0.3;
  transform: scale(1);
}

@media screen and (max-width: 1199px) {
  .highligth--big {
    height: 330px;
  }

  .highligth--big .box__image {
    height: auto;
  }
}

.box__content {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 3em;
  width: 100%;
  height: 100%;
  color: @white;
  &:before {
    content: "";
    position: absolute;
    top: 30px;
    right: 30px;
    bottom: 30px;
    left: 30px;
    border: 1px solid @white;
    transform: scale(1.1);
    transition: opacity 0.35s, transform 0.35s;
    opacity: 0;
  }
}

.box__content .ant-tag {
  padding: 5px 7px;
  font-size: 20px;
  margin: 0;
}

@media screen and (max-width: 576px) {
  .box__content {
    padding: 1em;
  }
  .box__content:before {
    top: 10px;
    right: 10px;
    bottom: 10px;
    left: 10px;
  }
}

.box__content__title,
.box__content__description {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: @white;
}

h3.ant-typography.box__content__title {
  margin-bottom: 0;
  color: @white;
}

.highligth--medium .box__content__description,
.highligth--big .box__content__description {
  width: 50%;
}

.box__content__description {
  display: block;
  font-size: 14px;
}

.box__link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  text-indent: 200%;
  white-space: nowrap;
  font-size: 0;
  opacity: 0;
}

.feed-navbar {
  ul li {
    border-right: 1px solid #3467a62c;
    font-weight: bold;
  }

  ul li:not(:first-of-type) {
    border-left: none;
  }
}

.custom-tag-gamme-neo {
  color: #fff;
  font-weight: 600;
  display: flex;
  justify-content: center;
  font-size: 10px;
  letter-spacing: 2px;
  line-height: 150%;
  text-transform: uppercase;
}
