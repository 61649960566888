.react-select-container {
  width: 100%;
}

.add-button {
  height: 32px !important;
  border: 1px solid hsl(0, 0%, 80%) !important;
  border-radius: 2px;
  margin-left: 2px;
}
