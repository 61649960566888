// Icon Override Mixin
.icon-override(@icon) {
  &:before {
    content: @icon;
  }
}

// === MIXINS === //
.border-radius(@val) {
  -moz-border-radius: @val;
  -webkit-border-radius: @val;
  border-radius: @val;
}

.border-radius-right(@val) {
  -moz-border-radius-topright: @val;
  -webkit-border-top-right-radius: @val;
  border-top-right-radius: @val;
  -moz-border-radius-bottomright: @val;
  -webkit-border-bottom-right-radius: @val;
  border-bottom-right-radius: @val;
}

.border-radius-left(@val) {
  -moz-border-radius-topleft: @val;
  -webkit-border-top-left-radius: @val;
  border-top-left-radius: @val;
  -moz-border-radius-bottomleft: @val;
  -webkit-border-bottom-left-radius: @val;
  border-bottom-left-radius: @val;
}

.border-radius-top(@val) {
  -moz-border-radius-topleft: @val;
  -webkit-border-top-left-radius: @val;
  border-top-left-radius: @val;
  -moz-border-radius-topright: @val;
  -webkit-border-top-right-radius: @val;
  border-top-right-radius: @val;
}

.border-radius-bottom(@val) {
  -moz-border-radius-bottomleft: @val;
  -webkit-border-bottom-left-radius: @val;
  border-bottom-left-radius: @val;
  -moz-border-radius-bottomright: @val;
  -webkit-border-bottom-right-radius: @val;
  border-bottom-right-radius: @val;
}

.linear-gradient(@top, @bottom) {
  background: @top; /* Old browsers */
  background: -moz-linear-gradient(top, @top 0%, @bottom 100%); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, @top),
    color-stop(100%, @bottom)
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, @top 0%, @bottom 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, @top 0%, @bottom 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, @top 0%, @bottom 100%); /* IE10+ */
  background: linear-gradient(to bottom, @top 0%, @bottom 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
}

.linear-gradient-left(@left, @right) {
  background: @left; /* Old browsers */
  background: -moz-linear-gradient(left, @left 0%, @right 100%); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0%, @left),
    color-stop(100%, @right)
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(left, @left 0%, @right 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(left, @left 0%, @right 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(left, @left 0%, @right 100%); /* IE10+ */
  background: linear-gradient(to right, @left 0%, @right 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=@left, endColorstr=@right,GradientType=1 ); /* IE6-9 */
}

.transition(@transition) {
  -moz-transition: @transition;
  -o-transition: @transition;
  -webkit-transition: @transition;
  transition: @transition;
}

.transition-property(@property) {
  -webkit-transition-property: @property;
  -moz-transition-property: @property;
  -ms-transition-property: @property;
  -o-transition-property: @property;
  transition-property: @property;
}

.transition-duration(@duration) {
  -moz-transition-duration: @duration;
  -webkit-transition-duration: @duration;
  -o-transition-duration: @duration;
  transition-duration: @duration;
}

.transition-timing-function(@function) {
  -webkit-transition-timing-function: @function;
  -moz-transition-timing-function: @function;
  -o-transition-timing-function: @function;
  transition-timing-function: @function;
}

.opacity(@opacity) {
  opacity: @opacity;
  @opacity-ie: @opacity * 100;
  filter: alpha(opacity= @opacity-ie);
}

.shadow(@value) {
  -webkit-box-shadow: @value;
  -moz-box-shadow: @value;
  box-shadow: @value;
}
