.accounting-firm__info {
  padding-top: 6px;
  margin-bottom: 5px;
}

.company-detail__div .ant-card-body {
  padding: 6px;
}

.application-icon {
  position: absolute;
  height: 70px;
  width: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
}
