.ant-table-thead {
  .ant-table-thead-danger {
    background-color: #ff0000 !important;
  }

  .ant-table-thead-warning {
    background-color: #ffcc00 !important;
  }

  .ant-table-thead-success {
    background-color: #99cc00 !important;
  }
  .ant-table-thead-primary {
    background-color: @primary-color !important;
    color: white;
  }
}

.stripped-cell-primary {
  background-color: lighten(@primary-color, 40);
}

.users-transfer-list .ant-transfer-list {
  width: 356px !important;
}

.ant-table-content {
  overflow: auto;
}
