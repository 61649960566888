.ant-layout-header {
  background: @primary-color;
}

.ant-layout-header.header {
  .ant-btn-link {
    color: white;
  }
  .accounting-firm-select {
    color: white;
  }
}

.ant-avatar {
  background: #2d7db8;
}

.topbar__button {
  position: relative;
  color: @topbar-item-color;
  padding: 7px 10px !important;
  display: inline-block;
  text-decoration: none;
  .transition(color @transition-duration);
  overflow: visible;

  &:hover {
    color: @topbar-item-hover-color;
  }

  &:focus {
    box-shadow: none;
  }
}

.topbar__menu-button {
  cursor: pointer;

  display: flex;
  align-items: center;

  text-decoration: none;
  color: @topbar-item-color;
  .transition(color @transition-duration);

  &:hover {
    color: @topbar-item-hover-color;
  }
}

.topbar__button.p-link {
  display: flex;
  align-items: center;
  border-radius: 0;
  text-transform: uppercase;
}

.topbar__text {
  text-transform: none;
  font-weight: normal;
  font-size: 14px;
}

.topbar__button-icon-svg {
  margin: 0 5px;
  font-size: 20px;
}

.toopbar__menu .p-link {
  &:focus {
    box-shadow: none;
  }
}

.toopbar__menu {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 64px;
}

.aditional-info-header {
  margin-left: auto;
  margin-right: 0;
}

@media (max-width: 375px) {
  .ant-layout-header.header .logo {
    max-width: 200px;
  }
}

@media (max-width: 991px) {
  .topbar__button {
    color: @text-color;
  }

  .toopbar__menu {
    margin-top: 53px;
    flex-direction: column;
  }
}

@media (min-width: 992px) and (max-width: 1050px) {
  .ant-layout-header.header {
    padding: 0 5px;
  }
}
