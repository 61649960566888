.p-card {
  background-color: #ffffff;
  padding: 0.5em;
  margin-bottom: 8px;
  .border-radius(@border-radius);
}

.news-preview {
  .ant-card-body {
    height: calc(100vh - 153px);
    overflow: auto;
  }
}
