.notifications-area {
  transition: flex 0.35s linear;
  overflow: hidden;
}

.notifications {
  background-color: rgba(@white, 0.6);
  height: @portal-height;
  width: 400px;
  padding: 15px;
  overflow: hidden;
}

@media screen and (max-width: 1599px) {
  .notifications {
    width: 300px;
  }
}

.notification__title {
  color: @primary-color;
}

.notifications .ant-list-item {
  background-color: transparent;
}

.notifications .ant-btn-link > span {
  text-decoration: underline;
}

.notifications .ant-space-vertical {
  width: 100%;
}

.ant-typography.ant-typography-secondary.notification--unread {
  color: black;
  &:before {
    content: "\2022";
    margin-right: 10px;
    font-weight: bold;
  }
}

.notifications h3.ant-typography {
  font-weight: normal;
  font-size: 22px;
}

.notifications .ant-divider-horizontal {
  font-weight: bold;
}

.notifications .ant-list-items .ant-divider-horizontal {
  margin: 0;
}

.notifications .notification__title_text,
.notifications .ant-list {
  transition: opacity 0.35s linear;
}

.notifications--hidden .notification__title_text,
.notifications--hidden .ant-list {
  opacity: 0;
}

.button-notification {
  position: fixed;
  bottom: 0;
  right: 0;
}
