.ant-row.flex-direction-column {
  flex-direction: column;
}

.flex-col-align-right {
  display: inherit;
  justify-content: flex-end;
}

.table-filter-select .ant-select {
  width: 100%;
}

//.align-absolute-center {
//  display: flex;
//  justify-content: center;
//  align-items: center;
//}

.align-edit-button svg {
  position: relative;
  top: 1px;
  left: 1px;
}

.align-delete-button svg {
  position: relative;
  top: 1px;
}

.add-btn {
  float: right;
  margin-top: 4px;
}
