.legend-block {
  display: inline-block;
  margin-right: 15px;
  &:last-child {
    margin-right: 0;
  }
}

.square {
  display: inline-block;
  margin-right: 5px;
  width: 10px;
  height: 10px;
  &--blue {
    background-color: @blue;
  }
  &--green {
    background-color: green;
  }
  &--red {
    background-color: red;
  }
  &--yellow {
    background-color: @neo-compta-color;
  }
}

body table.month td.day .day-content:hover {
  cursor: default;
  background: transparent;
}

.calendar table td.day {
  font-size: 14px;
}

.calendar .months-container div.month-container.month-2 {
  width: 25%;
}

.tippy-box[data-theme~="blue"] {
  background-color: @blue;
  color: @white;
  &[data-placement^="top"] > .tippy-arrow::before {
    border-top-color: @blue;
  }
  &[data-placement^="bottom"] > .tippy-arrow::before {
    border-bottom-color: @blue;
  }
  &[data-placement^="left"] > .tippy-arrow::before {
    border-left-color: @blue;
  }
  &[data-placement^="right"] > .tippy-arrow::before {
    border-right-color: @blue;
  }
}

.tippy-box[data-theme~="red"] {
  background-color: red;
  color: @white;
  &[data-placement^="top"] > .tippy-arrow::before {
    border-top-color: red;
  }
  &[data-placement^="bottom"] > .tippy-arrow::before {
    border-bottom-color: red;
  }
  &[data-placement^="left"] > .tippy-arrow::before {
    border-left-color: red;
  }
  &[data-placement^="right"] > .tippy-arrow::before {
    border-right-color: red;
  }
}

.tippy-box[data-theme~="green"] {
  background-color: green;
  color: @white;
  &[data-placement^="top"] > .tippy-arrow::before {
    border-top-color: green;
  }
  &[data-placement^="bottom"] > .tippy-arrow::before {
    border-bottom-color: green;
  }
  &[data-placement^="left"] > .tippy-arrow::before {
    border-left-color: green;
  }
  &[data-placement^="right"] > .tippy-arrow::before {
    border-right-color: green;
  }
}

.tippy-box[data-theme~="yellow"] {
  background-color: @neo-compta-color;
  color: @text-color;
  &[data-placement^="top"] > .tippy-arrow::before {
    border-top-color: @neo-compta-color;
  }
  &[data-placement^="bottom"] > .tippy-arrow::before {
    border-bottom-color: @neo-compta-color;
  }
  &[data-placement^="left"] > .tippy-arrow::before {
    border-left-color: @neo-compta-color;
  }
  &[data-placement^="right"] > .tippy-arrow::before {
    border-right-color: @neo-compta-color;
  }
}

.day--today {
  background-color: @blue;
  color: @white;
}

.flex-box.company-detail__content > .p-card.p-component {
  overflow: hidden;
}

.month-container {
  height: 235px !important;
}

.calendar {
  overflow: visible !important;
  background: #fff;
}

.calendar:hover {
  &.calendar::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-radius: 5px;
  }
}

.calendar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.calendar::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 10px;
}

.calendar-header {
  margin-bottom: 5px !important;
  border-right: 0 !important;
  border-left: 0 !important;
}
