@portal-height: calc(100vh - 64px);

.portal {
  padding: 0;
  height: @portal-height;
  overflow: auto;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    background-image: url("../../images/pexels-johannes-plenio-1103970.jpg");
    background-size: cover;
  }
}

.portal .ant-space {
  width: 100%;
}

@media screen and (max-width: 576px) {
  .portal h1.ant-typography {
    font-size: 2rem;
  }
}
