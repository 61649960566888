@component-height: 32px;

.async-select {
  border-radius: 0px;
}

.ant-form-item-has-error .async-select .async-select__control {
  border-color: @error-color;

  &:hover,
  &.async-select__control--is-focused {
    border-color: @error-color;
    box-shadow: 0 0 0 2px rgba(@error-color, 0.25);
  }
}

.async-select .async-select__control {
  min-height: @component-height;
  height: @component-height;

  border: 1px solid;
  border-radius: 2px;
  border-color: #d9d9d9;

  &:hover {
    border-color: lighten(@primary-color, 7%);
    cursor: pointer;
  }

  &.async-select__control--menu-is-open {
    border-color: lighten(@primary-color, 7%);
    box-shadow: 0 0 0 2px rgba(@primary-color, 0.25);
  }

  &.async-select__control--is-focused {
    border-color: lighten(@primary-color, 7%);
    box-shadow: 0 0 0 2px rgba(@primary-color, 0.25);
  }
}

.async-select .async-select__value-container {
  height: @component-height;
  padding: 0 11px;

  .async-select__single-value {
    color: rgba(#000, 0.65);
  }
}

.async-select .async-select__indicators {
  height: @component-height;
  color: rgba(#000, 0.25);
  font-size: 12px;

  .anticon,
  .async-select__indicator {
    padding: 6px 8px;
  }

  .async-select__clear-indicator {
    color: rgba(#000, 0.25);
    padding: 6px 0;
    padding-left: 4px;
  }

  .anticon.anticon-loading {
    font-size: 14px;
  }
}

.async-select .async-select__placeholder {
  color: #bfbfbf;
}

.async-select__menu {
  z-index: 1000 !important;
}

.async-select__menu .async-select__option {
  color: rgba(#000, 0.65);
  cursor: pointer;

  &.async-select__option--is-focused,
  &:active {
    background-color: rgba(@primary-color, 0.1);
  }

  &.async-select__option--is-selected {
    font-weight: 600;
    background-color: rgba(@primary-color, 0.1);
  }
}

.ant-select-dropdown {
  z-index: 2000;
}

.async-select__option.creatable-option {
  background-color: rgba(@warning-color, 0.1);
}

.ant-form-item-has-error {
  .select-component .react-select__control,
  .ant-input[disabled],
  .ant-input-number-disabled,
  .ant-picker.ant-picker-focused,
  .ant-picker {
    border-color: @error-color;
  }
}
