/* Dashboard */

.summary {
  position: relative;
  height: calc(100% - 8px);

  .title {
    font-size: 20px;
  }

  .detail {
    color: @text-secondary-color;
    display: block;
    margin-top: 10px;
  }

  .count {
    color: #ffffff;
    position: absolute;
    top: 14px;
    right: 28px;
    font-size: 24px;
    padding: 7px 14px;
    .border-radius(@border-radius);
  }
}

.dashboard-logo--position {
  position: absolute;
  bottom: 20px;
  right: 40px;
  opacity: 0.3;
}

.logo-xl {
  width: 800px;
}

.logo-sm {
  width: 300px;
}

body .p-accordion .p-accordion-header:not(.p-disabled).p-highlight a {
  border-color: @primary-color;
  background-color: @primary-color;
}

body .p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover a {
  border-color: @darker-blue;
  background-color: @darker-blue;
}

body .p-accordion .p-accordion-header--error a {
  background-color: @error-color;
}

body .p-accordion .p-accordion-header--warnings a {
  background-color: @warning-color;
}

.accordion-background--warning .ant-collapse-header {
  color: @warning-color !important;
}

.accordion-background--error .ant-collapse-header {
  color: @error-color !important;
}

@media (max-width: 378px) {
  .dashboard-logo--position {
    max-width: 50%;
  }

  .ant-drawer-right > .ant-drawer-content-wrapper {
    width: 100% !important;
  }
}
