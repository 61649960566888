.ant-list-item {
  background: white;
  padding: 8px;
  cursor: pointer;
}

.news-list {
  height: calc(100vh - 154px);
  overflow: auto;
  background-color: white;
}

@media (min-width: 768px) and (max-width: 991px) {
  .ant-list-item {
    display: block;
  }
}

@media (max-width: 375px) {
  .ant-list-item {
    display: block;
  }
}
