.sidebar {
  background-color: @white;
  position: fixed;
  top: 64px;
  width: @sidebar-width;
  height: 100%;
  z-index: 999;
  padding: 30px 0;
  overflow-y: auto;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  .transition(left @transition-duration);
  .shadow(0 0 6px 0 rgba(0, 0, 0, 0.2));
}

.icon-container .icon-app-btn:hover {
  background: rgba(218, 218, 218, 0.2) !important;
}

.sidebar-container .ant-space {
  float: left;
}

.icon-title {
  color: #fff;
}

.disable-btn .icon-title {
  color: #fff;
}

.icon-title {
  margin-left: 20px;
}

.sidebar-footer-width {
  width: 200px !important;
}

.sidebar-footer {
  width: 55px !important;
}

.ant-layout-sider-trigger {
  background: #295791;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border: 0 !important;
}

.ant-menu-item {
  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-item {
  padding-left: 12px !important;
}

.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: rgba(255, 255, 255, 0.2) !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.antd-sider .ant-menu-item {
  padding-left: 12px !important;
}

.feed-container {
  padding-left: 260px;
  transition: width 0.2s linear;
}

.feed-container.full {
  padding-left: 60px;
}

.menu-sider {
  padding-left: 60px;
  max-height: calc(100vh - 64px);
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(@primary-color, 0.3);
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(52, 103, 166, 0.294);
    background-color: #f5f5f5;
  }
}
