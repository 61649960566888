.client-secrets-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.connect-btn {
  width: 30px !important;
  height: 24px !important;

  svg {
    margin-top: 4px;
    color: #6cc070;
  }
}
