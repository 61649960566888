.custom-phone-input input {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative;
  display: inline-block;
  width: 100% !important;
  height: 32px !important;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff !important;
  background-image: none;
  border: 1px solid #d9d9d9 !important;
  border-radius: 2px !important;
  transition: all 0.3s;

  &:hover {
    border-color: #5e99ab !important;
    border-right-width: 1px !important;
  }

  &:focus {
    border-color: #5e99ab !important;
    box-shadow: 0 0 0 2px rgba(62, 132, 158, 0.2) !important;
    border-right-width: 1px !important;
    outline: 0 !important;
  }
}

.disabled-phone input {
  color: rgba(0, 0, 0, 0.25) !important;
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
  cursor: not-allowed !important;
  opacity: 1 !important;
}
